.theme-screen {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white; // Corrected syntax

  h2 {
    margin-bottom: 20px;
  }

  .previews-wrapper {
    display: flex;
    flex-wrap: wrap; // Allow wrapping of theme previews
    gap: 20px; // Space between previews
    justify-content: center; // Center items within the container
  }
}

.preview-container {
  position: relative;
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;

  .left-panel {
    width: 30%;
    background: #e3e3e3;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px;

    .nav-item {
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #bbb;
      border-radius: 4px;
    }

    .sub-nav-item {
      width: 100%;
      height: 3px;
      margin: 0px 10px;
      background: #ddd;
      border-radius: 4px;
    }
  }

  .right-panel {
    flex-grow: 1;
    background: #f5f5f5;
    padding: 10px;

    .header {
      height: 30px;
      background: #ccc;
      margin-bottom: 10px;
      border-radius: 4px;
    }

    .content-item {
      height: 20px;
      background: #ddd;
      margin-bottom: 5px;
      border-radius: 4px;
    }
  }
}

.radio-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  z-index: 99;
  gap: 5px;
}

.radio-button-inner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}
