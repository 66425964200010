.form {
  display: flex;
  flex: 1;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.textContainer {
  text-align: center;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.pinCodeContainer {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
}

.pinInput {
  width: 40px;
  height: 50px;
  margin: 0 5px;
  font-size: 24px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s ease;

  &:focus {
    border-color: #007bff;
  }
}

.buttonContainer {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.forgotLink{
  margin-top: 24px;
}
