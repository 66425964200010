@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.container-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.32px;
  color: $black;
}

.wrapper-attr {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}

.button {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 32px;
  padding: 4px 12px;
  text-transform: none;
  display: flex;
  align-items: center;
}

.wrapper-content {
  padding: 32px;
  background-color: $white;
}

.none {
  display: none;
}
