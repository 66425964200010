@import 'styles/variables';

// Main table wrapper
.table {
  width: 'auto';
  max-width: 75vw;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 10px; // Adjust height for horizontal scrollbar
  }

  &::-webkit-scrollbar-track {
    background: #f0f3f6; // Color of the scrollbar track
  }

  &::-webkit-scrollbar-thumb {
    background-color: blue; // Color of the scrollbar thumb
    border-radius: 4px; // Rounded corners for the thumb
    border: 2px solid $blue; // Padding-like effect
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $blue; // Darker color on hover
  }
}

// Header row styling
.table-header {
  display: flex;
  width: 100vw;
  flex-direction: row;
  background-color: #f0f3f6;
  gap: 8px;
}

// Header column styling
.table-column {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding: 16px 8px;
  overflow: hidden;
  // Removed text-overflow and ellipsis to prevent cutting
  white-space: normal; // Allow text to wrap
  word-wrap: break-word; // Break long words if necessary
}

// Header text styling
.header-text {
  font-size: 14px;
  font-weight: 500;
  color: #4f6281;
}

// Row styling
.table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  width: 100vw;
  padding: 0;
  cursor: pointer;
  border-bottom: 1px solid $light-white;
  align-items: center;

  &:hover {
    background-color: $primary-light-blue;
  }
}

// Column styling
.table-column {
  flex: 1;
  min-width: 100px;
  display: flex;
  justify-content: flex-start;
  padding: 16px 8px;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
}

// Row text styling
.column-text {
  color: #050e1e;
  font-size: 16px;
  font-family: Gilroy;
  font-weight: 600;
  line-height: 24px;

  // Special styles for dates
  &.date {
    font-size: 24px;
    font-weight: 500;
    color: $primary-dark-grey;
  }
}
