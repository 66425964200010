@import 'styles/variables';

.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.innerContainer {
  flex: 1;
  width: 100%;
  background-color: #e0e5ec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
}

.contentBox {
  flex: 1;
  width: 100%;
  padding: 32px 0px;
  background-color: #f0f3f6;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.card {
  flex: 1;
  margin: 24px 56px;
  padding: 56px;
  background-color: white;
  box-shadow: 0px 4px 4px -4px rgba(12, 12, 13, 0.05);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  background-color: $white;
  border-top: 1px solid $light-white;
  gap: 12px;
}
.button {
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
}
.button-back {
  background-color: $white;
  color: $brand;
  border: 2px solid $brand;
}
