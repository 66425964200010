.requirementsList {
    list-style: none;
    padding: 0;
  }
  
  .requirement {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .requirement svg {
    margin-right: 5px;
  }