@import 'styles/variables';

$theme-1: (
  --dashboard-sidepanel: #2d2eb7,
  --dashboard-sidepanel-border: 1px solid #8997ae,
  --dashboard-item-background-hover: #000073,
  --dashboard-item-text-inactive: #8997ae,
  --dashboard-item-text: #fff,
  --dashboard-line-bg: #fff,
  --dashboard-main-bg: #e0e5ec,
);

$theme-2: (
  --dashboard-sidepanel: #009c43,
  --dashboard-sidepanel-border: 1px solid #c4ced7,
  --dashboard-item-background-hover: #067536,
  --dashboard-item-text-inactive: #085f2f,
  --dashboard-item-text: #fff,
  --dashboard-line-bg: #fff,
  --dashboard-main-bg: #e5f1da,
);

$theme-3: (
  --dashboard-sidepanel: #1f1f1f,
  --dashboard-sidepanel-border: 1px solid #333,
  --dashboard-item-background-hover: #444,
  --dashboard-item-text-inactive: #888,
  --dashboard-item-text: #fff,
  --dashboard-line-bg: #fff,
  --dashboard-main-bg: #e0e5ec,
);

$theme-4: (
  --dashboard-sidepanel: #004f73,
  --dashboard-sidepanel-border: 1px solid #006c91,
  --dashboard-item-background-hover: #005f85,
  --dashboard-item-text-inactive: #a3d3e6,
  --dashboard-item-text: #fff,
  --dashboard-line-bg: #fff,
  --dashboard-main-bg: #e3f5fb,
);

$theme-5: (
  --dashboard-sidepanel: #ff4500,
  --dashboard-sidepanel-border: 1px solid #ff7043,
  --dashboard-item-background-hover: #ff6347,
  --dashboard-item-text-inactive: #ffe0b3,
  --dashboard-item-text: #fff,
  --dashboard-line-bg: #fff,
  --dashboard-main-bg: #ffe6cc,
);

$theme-6: (
  --dashboard-sidepanel: #2e8b57,
  --dashboard-sidepanel-border: 1px solid #3cb371,
  --dashboard-item-background-hover: #66cdaa,
  --dashboard-item-text-inactive: #b2dfdb,
  --dashboard-item-text: #fff,
  --dashboard-line-bg: #fff,
  --dashboard-main-bg: #e8f5e9,
);

// Theme mixin to apply CSS variables
@mixin apply-theme($theme) {
  --dashboard-sidepanel: #{map-get($theme, --dashboard-sidepanel)};
  --dashboard-sidepanel-border: #{map-get($theme, --dashboard-sidepanel-border)};
  --dashboard-item-background-hover: #{map-get($theme, --dashboard-item-background-hover)};
  --dashboard-item-text-inactive: #{map-get($theme, --dashboard-item-text-inactive)};
  --dashboard-item-text: #{map-get($theme, --dashboard-item-text)};
  --dashboard-line-bg: #{map-get($theme, --dashboard-line-bg)};
  --dashboard-main-bg: #{map-get($theme, --dashboard-main-bg)};
}

:root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  @include apply-theme($theme-1);
}

[data-theme='theme-0'] {
  @include apply-theme($theme-1);
}

[data-theme='theme-1'] {
  @include apply-theme($theme-1);
}

[data-theme='theme-2'] {
  @include apply-theme($theme-2);
}

[data-theme='theme-3'] {
  @include apply-theme($theme-3);
}

[data-theme='theme-4'] {
  @include apply-theme($theme-4);
}

[data-theme='theme-5'] {
  @include apply-theme($theme-5);
}

[data-theme='theme-6'] {
  @include apply-theme($theme-6);
}

body {
  transition: background-color 0.3s, color 0.3s;
}
