@import 'styles/variables';

.container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.info {
  display: flex;
  flex-direction: column;
}
.organization {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;
  color: $black;
}
.position {
  color: $deep-grey;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.12px;
}

.changeUser {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 8px;
}

.button {
  width: 100%;
  height: 40px;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
}
.button-add {
  svg {
    width: 16px;
    height: 16px;
    fill: $green;
    stroke: $green;
  }
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.itemProfile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}