/* Footer.module.scss */

@import 'styles/variables';

.root {
  /* Removed z-index: -1; unless it's necessary for your specific design */

  /* Flex properties are handled by the parent container */

  z-index: -1;
  background-image: url('/dynamic/assets/footer.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  height: 300px;

  /* Adjust background size for different screen widths */
  background-size: 160%;

  @media (min-width: $desktop) {
    height: 160px;
    background-size: 100%;
  }
}
