.form {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}

.forgotLink {
  color: black;
  margin-top: 10px;
  text-align: right;
}

.passwordRequirements {
  margin-top: 10px;
}

.buttonContainer {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
