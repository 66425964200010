/* LayoutAuth.module.scss */

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensures the container takes at least the full viewport height */
  }
  
  .content {
    flex: 1; /* Allows the main content to grow and fill available space */
  }
  