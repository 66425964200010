$brand: #ff3e00;
$green: #5ec800;
$red: #d60000;
$grey: #dedede;
$light-blue: #abbae2;
$blue: #2d2eb7;
$deep-blue: #000073;
$white: #ffffff;
$light-white: #e0e5ec;
$primary-dark-grey: #546a8c;
$light-grey: #c4ced7;
$deep-grey: #8997ae;
$black: #050e1e;
$light-green: #35a388;
$primary-grey: #7f90ac;
$primary-light-blue: #ecf3fd;
$secondary-red: #ff0b37;
$secondary-light-red: #fcdde2;
$secondary-light-grey: #f0f3f6;
$secondary-primary-grey: #e6e9ee;
$tertiary-light-grey: #d3d9e4;
$yellow: #fde047;
$secondary-light-green: #d9f4e6;
$blue-grey: #344659;
$secondary-light-blue: #bfd8ff;
$secondary-blue: #4b4df8;
$dark-dark-68: #586c8c;
$dark-dark-48: #8997ae;
$secondary-dark-grey: rgba(84, 106, 140, 0.2);
$secondary-yellow: #fb8832;
$secondary-light-yellow: #ffe0b1;
$secondary-black: rgba(50, 59, 75, 0.4);
$secondary-light-black: rgba(79, 98, 129, 0.2);
$tertiary-light-black: rgba(127, 144, 172, 0.16);
$secondary-primary-dark-grey: #4f6281;
$dark: #333;
$secondary-green: #35a388;
$primary-light-grey: #becadc;
$stroke-normal-grey: #c4ced7;
$tertiary-light-yellow: #fcf6ef;
$background-light-grey-2: #f8f9fb;
