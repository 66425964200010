@import 'styles/variables';

.title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.32px;
  color: $black;
}
.container-content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.top-inner-content {
  background-color: $white;
  padding: 40px 40px 50px 40px;

  display: flex;
  flex-direction: column;
  gap: 40px;
}

.bottom-outer-content {
  background-color: $white;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: 8px;
}

.button {
  height: 40px;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
}

.wrapper-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-cancel-create-new-register {
  font-weight: 500;
}
