.file-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background: #f9f9f9;
}

.file-name {
  font-size: 14px;
  color: #333;
}

.remove-button {
  background: none;
  border: none;
  color: #ff4d4f;
  cursor: pointer;
}

.disabled-button {
  cursor: not-allowed;
  color: #d9d9d9;
}
