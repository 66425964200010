@import 'styles/variables';

.form {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}

.container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}

.forgotLink {
  color: black;
  margin-top: 10px;
  text-align: right;
}

.passwordRequirements {
  margin-top: 10px;
}

.buttonContainer {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.successMessage {
  display: flex;
  flex: 0.8;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.errorMessage {
  display: flex;
  flex: 0.8;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.errorMessage svg path {
  fill: $red;
}
