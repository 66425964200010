@import 'styles/variables';

.container {
  display: flex;
  flex: 1;
  min-height: 100vh;
  background-color: var(--dashboard-main-bg);
  transition: grid-template-columns 0.3s ease-in-out;
}

.content {
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
