@import 'styles/variables';

.container {
  grid-area: sidenav;
  display: flex;
  background-color: var(--dashboard-sidepanel);
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 24px 24px 40px 24px;
}

.wrapperLogo {
  padding-bottom: 10.5px;
  border-bottom: var(--dashboard-sidepanel-border);
}

.content {
  flex: 1;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.limitedUser {
  font-size: 12px;
  line-height: 150%;
  margin-top: 24px;
  color: #ff585d;
}
