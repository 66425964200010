.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.innerContainer {
  flex: 1;
  width: 100%;
  background-color: #e0e5ec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.32px;
  color: #050e1e;
}

.contentBox {
  flex: 1;
  width: 100%;
  padding: 32px 0px;
  background-color: #f0f3f6;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.card {
  flex: 1;
  background-color: white;
  box-shadow: 0px 4px 4px -4px rgba(12, 12, 13, 0.05);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.divider {
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: #4f6281;
}

.value {
  font-size: 16px;
  font-weight: 500;
  color: #050e1e;
}

.buttonsContainer {
  padding: 16px 24px;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.cancelButton {
  border: 2px solid #4f6281;
}

.editButton {
  border: 2px solid #5ec800;
  color: #5ec800;
}

.approveButton {
  background-color: #5ec800;
  color: white;
}

.buttonSettings span {
  margin-right: 0px;
}
