@import 'styles/variables';

.title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.32px;
  color: $black;
}
.container-content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.header {
  padding: 16px 24px;
  background-color: $white;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main {
  background-color: $white;
}
.button {
  height: 40px;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
}

.buttonSettings span {
  margin-right: 0px;
}

.button-create-register {
  font-weight: 500;
}


.search {
  min-width: 438px;
}
.wrapper-search {
  display: flex;
  gap: 12px;
}

.wrapper-button {
  display: flex;
  gap: 16px;
}

.contract-container {
  padding: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid $light-white;
  }
}
.contract-content {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: $primary-light-blue;
  }
}
.contract-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contract-main {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.main-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.organization {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $black;
}
.wrapper-name {
  display: flex;
  align-items: center;
  gap: 12px;
}
.name {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.24px;
  color: $black;
}
.number {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  color: $primary-dark-grey;
}
.date {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  color: $primary-dark-grey;
}

.label-container {
  padding: 4px 12px;
  border: 1px solid $primary-grey;
}
.label-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary-grey;
}
